const theme = {
  headerHeight: {
    height: '44px',
    minHeight: '44px',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default theme;
