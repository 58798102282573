/* eslint no-underscore-dangle: 0, no-undef: 0 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/browser';
import Authorization from 'Auth';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { styles as s } from 'stylesheet';
import isProduction from 'utils/isProduction';

import './index.css';
import './components/animations/AnimateFromTop.css';
import 'react-flexbox-grid/dist/react-flexbox-grid.css';
import 'react-toastify/dist/ReactToastify.css';

if (isProduction) {
  Sentry.init({
    dsn: 'https://aae5b130a0f54b57a2ecd2f151ffb18a@sentry.io/4557391',
    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    release: 'poursteady-app@2.3.12',
    integrations: [Sentry.browserTracingIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const StyledToast = styled.div({
  '.Toastify': css(s.relative, {
    zIndex: 99999999,
    '.Toastify__toast-body': css(s.h3),
  }),
});

const container = document.getElementById('app-root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <>
    <BrowserRouter>
      <Authorization />
    </BrowserRouter>
    <StyledToast>
      <ToastContainer />
    </StyledToast>
  </>,
);
