import breakpoints from './breakpoints';

const fonts = {
  sourceSansPro: {
    fontFamily: "'Source Sans Pro', sans-serif",
    fontWeight: '600',
  },
  sourceCodePro: {
    fontFamily: "'Source Code Pro', monospace",
    fontWeight: '600',
  },
};

const copy = {
  h1: {
    fontSize: '20px',
    ...fonts.sourceSansPro,
  },
  h2: {
    fontSize: '10px',
    ...fonts.sourceSansPro,
  },
  h3: {
    fontSize: '17px',
    ...fonts.sourceSansPro,
  },
  h4: {
    fontSize: '20px',
    ...fonts.sourceSansPro,
    [breakpoints.desktop]: {
      fontSize: '24px',
    },
  },
  h5: {
    textTransform: 'uppercase',
    fontSize: '13px',
    letterSpacing: '1px',
    lineHeight: '1em',
    ...fonts.sourceCodePro,
  },
  p: {
    fontSize: '13px',
    lineHeight: '1.1em',
    ...fonts.sourceCodePro,
  },
};

export default { ...fonts, ...copy };
