export default {
  white: '#ffffff',
  black: '#000',
  darkGrey: '#A1A1A1',
  grey: '#C8C7CC',
  lightGrey: '#F1F1F1',
  green: '#4CD964',
  red: '#FF3B30',
  blue: 'rgb(48,123,246)',
  yellow: 'yellow',
};
