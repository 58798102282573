import React, { useState, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { ApolloClient, HttpLink, concat, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import AuthContext from 'context/AuthContext';
import Loading from 'components/Loading';
import loadable from '@loadable/component';
import * as Sentry from '@sentry/browser';
import isProduction from 'utils/isProduction';

const options = { fallback: <Loading /> };
const LoadableLogin = loadable(() => import('views/Login'), options);
const LoadableApp = loadable(() => import('./App'), options);

const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY_PS_MAIN;
const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN_PS_MAIN;
const FIREBASE_DATABASE_URL =
  process.env.REACT_APP_FIREBASE_DATABASE_URL_PS_MAIN;
const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID_PS_MAIN;
const FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET_PS_MAIN;
const FIREBASE_MESSAGE_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID_PS_MAIN;
const FIREBASE_MESSAGE_APP_ID =
  process.env.REACT_APP_FIREBASE_MESSAGE_APP_ID_PS_MAIN;

firebase.initializeApp({
  apiKey: FIREBASE_API_KEY,
  authDomain: FIREBASE_AUTH_DOMAIN,
  databaseURL: FIREBASE_DATABASE_URL,
  projectId: FIREBASE_PROJECT_ID,
  storageBucket: FIREBASE_STORAGE_BUCKET,
  messageSenderId: FIREBASE_MESSAGE_SENDER_ID,
  appId: FIREBASE_MESSAGE_APP_ID,
});

const firebaseAuth = firebase.auth();
const link = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_ENDPOINT });

const authLink = setContext(async (operation, { headers }) => {
  const token = await firebaseAuth.currentUser.getIdToken(true);
  return {
    headers: {
      ...headers,
      authorization: token || null,
    },
  };
});

const cache = new InMemoryCache({
  // cacheRedirects: {},
  typePolicies: {
    RecipeAttributes: {
      merge: true,
    },
    RecipeRelationships: {
      merge: true,
    },
    MachineRelationships: {
      merge: true,
    },
    MachineAttributes: {
      merge: true,
    },
  },
});

const Authorization = () => {
  const [initialized, setInitialized] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [authStatus, setAuthStatus] = useState('IDLE');
  const [resetStatus, setResetStatus] = useState('IDLE');
  const [signUpStatus, setSignUpStatus] = useState('IDLE');
  const [error, setError] = useState(null);

  useEffect(
    () =>
      firebaseAuth.onAuthStateChanged(async (user) => {
        setError(null);
        setAuthStatus('PENDING');
        if (user) {
          if (isProduction) {
            Sentry.getCurrentScope().setUser({ id: user.uid });
          }

          if (window && window.analytics) {
            window.analytics.identify(user.uid, {
              name: user.displayName,
              email: user.email,
            });
          }

          const token = await user.getIdToken(true);
          if (token) {
            setLoggedIn(true);
          } else {
            setLoggedIn(false);
          }

          setAuthStatus('FULFILLED');
          setInitialized(true);
        } else {
          setLoggedIn(false);
          setAuthStatus('IDLE');
          setInitialized(true);
        }
      }),
    [],
  );

  if (!initialized) return <Loading />;

  const signInUser = async (email = '', password = '') => {
    try {
      setAuthStatus('PENDING');
      setError(null);
      await firebaseAuth
        .signInWithEmailAndPassword(email, password)
        .catch((e) => {
          setError(e);
          setAuthStatus('REJECTED');
        });
      return true;
    } catch (e) {
      return e;
    }
  };

  const resetPassword = async (email) => {
    try {
      setError(null);
      setResetStatus('PENDING');
      return await firebaseAuth
        .sendPasswordResetEmail(email)
        .then(() => {
          setResetStatus('FULFILLED');
        })
        .catch((e) => {
          setError(e);
          setResetStatus('REJECTED');
        });
    } catch (e) {
      return e;
    }
  };

  const client = new ApolloClient({
    link: concat(authLink, link),
    cache,
  });

  const signOutUser = async () => {
    try {
      await firebaseAuth.signOut();
      setLoggedIn(false);
      setAuthStatus('IDLE');
      await client.resetStore();
      return true;
    } catch (e) {
      return e;
    }
  };

  const signUpUser = async (email = '', password = '') => {
    try {
      setSignUpStatus('PENDING');
      setError(null);
      await firebaseAuth
        .createUserWithEmailAndPassword(email, password)
        .then(() => {
          setSignUpStatus('FULFILLED');
          // history.push('/')
        })
        .catch((e) => {
          setError(e);
          setSignUpStatus('REJECTED');
        });
      return true;
    } catch (e) {
      return e;
    }
  };

  const authContext = {
    actions: {
      resetPassword,
      signInUser,
      signOutUser,
      signUpUser,
    },
    error,
    status: {
      auth: authStatus,
      reset: resetStatus,
      signUp: signUpStatus,
    },
  };

  if (!loggedIn) {
    return (
      <AuthContext.Provider value={authContext}>
        <LoadableLogin />
      </AuthContext.Provider>
    );
  }

  return (
    <AuthContext.Provider value={authContext}>
      <ApolloProvider client={client}>
        <LoadableApp />
      </ApolloProvider>
    </AuthContext.Provider>
  );
};

export default Authorization;
