import { generate } from 'cooltilities';
import breakpoints from './breakpoints';
import colors from './colors';
import copy from './copy';
import theme from './theme';

const styles = {
  ...generate(colors),
  ...copy,
  ...theme,
};

export { breakpoints, colors, copy, styles };
